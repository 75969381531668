import config from '../../../../app.config.json';
import { ContentHubArticleCategory } from '../types/strapi/overrides';

export const GUIDES_FEATURE =
  config?.chBuyingFeature != null ||
  config?.chSellingFeature != null ||
  config?.chRentingFeature != null ||
  config?.chStudentsFeature != null
    ? config?.chBuyingFeature === 'true' &&
      config?.chSellingFeature === 'true' &&
      config?.chRentingFeature === 'true' &&
      config?.chStudentsFeature === 'true'
    : true;
export const NEWS_FEATURE = config?.newsFeature != null ? config.newsFeature === 'true' : true;
export const CH_LOGIN_FEATURE = config?.chLoginFeature != null ? config.chLoginFeature === 'true' : true;
export const CH_HOME_FEATURE = config?.chHomeFeature != null ? config.chHomeFeature === 'true' : true;
export const CH_BUYING_FEATURE = config?.chBuyingFeature != null ? config.chBuyingFeature === 'true' : true;
export const CH_SELLING_FEATURE = config?.chSellingFeature != null ? config.chSellingFeature === 'true' : true;
export const CH_RENTING_FEATURE = config?.chRentingFeature != null ? config.chRentingFeature === 'true' : true;
export const CH_STUDENTS_FEATURE = config?.chStudentsFeature != null ? config.chStudentsFeature === 'true' : true;
export const PARTIAL_GUIDES_FEATURE =
  GUIDES_FEATURE || CH_BUYING_FEATURE || CH_SELLING_FEATURE || CH_RENTING_FEATURE || CH_STUDENTS_FEATURE;

export const BUYING_CATEGORY = 'buying';
export const SELLING_CATEGORY = 'selling';
export const RENTING_CATEGORY = 'renting';
export const STUDENTS_CATEGORY = 'students';
export const GUIDE_CATEGORIES: ContentHubArticleCategory[] = [
  BUYING_CATEGORY,
  RENTING_CATEGORY,
  SELLING_CATEGORY,
  STUDENTS_CATEGORY,
];
export const NEWS_CATEGORIES: ContentHubArticleCategory[] = ['news-and-analysis'];

export const DEFAULT_META_DESCRIPTION =
  'Discover Useful Guides On Buying, Selling, Renting, Moving, Investing And Mortgages, As Well As Northern Ireland Housing Market Updates.';

export const CH_HOME_LINK = '/content-hub';
