import simonCommunityLogo from '@propertypal/shared/src/resources/logos/simon-community-ni-white.png';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import dayjs from 'dayjs';
import React from 'react';
import { Container } from './SCBanner.style';

const newYears = dayjs('2024-12-31T00:00:00.000Z');

const SCBanner = () => {
  const trackClick = () => {
    trackGaEvent('christmas_appeal_click', {});
  };

  const display = dayjs().isAfter(newYears) ? 'none' : 'flex';

  return (
    <>
      <Container style={{ display }}>
        <img src={simonCommunityLogo.src} alt="Simon Community" />

        <p>
          <b>Spread warmth this Christmas</b> - Donate to the <b>Simon Community NI</b> and help end homelessness
        </p>

        <a
          href="https://simoncommunity.org/get-involved/appeals/christmas-appeal"
          onClick={trackClick}
          target="_blank"
          rel="noreferrer"
        >
          DONATE
        </a>
      </Container>
    </>
  );
};

export default SCBanner;
