import { laptopMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ea5173;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 80px;
  gap: 15px;

  img {
    height: 68px;
  }

  p {
    color: ${({ theme }) => theme.white};
    text-align: center;
  }

  a {
    display: block;
    padding: 14px 38px;
    border-radius: 5px;
    background-color: #4c4c4c;
    color: ${({ theme }) => theme.white};
    font-size: 18px;
    font-weight: bold;

    &:hover {
      color: #ccc;
    }
  }

  @media (${laptopMax}) {
    padding: 0 15px;
  }

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
    height: auto;
    padding: 10px 15px;
    gap: 5px;

    img {
      height: 45px;
    }

    p {
      font-size: 14px;
    }

    a {
      padding: 12px 38px;
      font-size: 16px;
    }
  }
`;

export default Container;
