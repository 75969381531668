import { MY_DRAWN_AREA, MY_LOCATION } from '../../constants/search';
import { debounce } from '../../hooks/useDebounce';
import axios, { API_URL } from '../../services/axios';
import logging from '../../services/logging';
import { SavedSearch, SearchFormNlpResult } from '../../types/search';
import { getNlPFilters } from '../../utils/search';
import { setSavedSearch as setMapSavedSearch } from '../map';
import { propertySearch, setSavedSearch as setPropertySavedSearch } from '../properties';
import { AppThunk } from '../store';
import {
  clearNlpData,
  removeSavedSearch,
  SearchState,
  setSavedSearch,
  setSavedSearches,
  setMoreSavedSearches,
  setSavedSearchesError,
  setSavedSearchesLoading,
  initialState,
  setSearch,
  setView,
} from './search.slice';

const DEFAULT_SEARCH_TERMS = {
  currency: 'GBP',
  category: ['residential'],
  saleType: ['sale'],
};

type GetSavedSearches = (view: string, googleMapSize?: string) => AppThunk;

type SaveSearch = (name: string, emailFrequency: string) => AppThunk<Promise<boolean>>;

type LoadSavedSearch = (savedSearch: SavedSearch, imageLocator: string) => AppThunk;

type UpdateSavedSearch = (
  id: number,
  emailFrequency: string,
  selfOwned: boolean,
  name?: string,
) => AppThunk<Promise<{ success: boolean; toast?: string }>>;

type DeleteSavedSearch = (id: number, selfOwned: boolean) => AppThunk<Promise<boolean>>;

const LIMIT = 10;

const DEBOUNCE_TIME = process?.env?.JEST_WORKER_ID ? 0 : 500;

export const getNlpData = async (
  text: string,
  filters: SearchState['filters'],
  setNlpData: any,
  extraParams?: string,
) => {
  try {
    let params = `&category=${filters.category}&saleType=${filters.saleType}`;

    if (extraParams) params += `&${extraParams}`;
    if (filters.currency) params += `&currency=${filters.currency}`;
    if (filters.maxPrice) params += `&maxPrice=${filters.maxPrice}`;
    if (filters.minPrice) params += `&minPrice=${filters.minPrice}`;
    if (filters.polyIds.length) params += `&${filters.polyIds.map((id) => `polyId=${id}`).join('&')}`;

    const result = await axios<SearchFormNlpResult>(
      {
        url: `${API_URL}/search-form/nlp?q=${encodeURIComponent(text)}${params}`,
      },
      undefined,
    );

    setNlpData(result.data);

    return result.data;
  } catch (err: any) {
    return null;
  }
};

export const getNlpDataDebounced = debounce(getNlpData, DEBOUNCE_TIME);

export const getSavedSearches: GetSavedSearches = (view) => async (dispatch) => {
  dispatch(setSavedSearchesLoading({ loading: true, view }));

  try {
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/search-group?view=${view}&offset=0&limit=${LIMIT}`,
      },
      dispatch,
    );

    dispatch(
      setSavedSearches({
        data: result.data,
        view,
      }),
    );
  } catch (error: any) {
    // TODO - remove, only here to check there are no errors inside of setSavedSearches
    logging.logError(error);

    dispatch(setSavedSearchesError({ error, view }));
  }
};

export const getMoreSavedSearches: GetSavedSearches = (view) => async (dispatch, getState) => {
  const saved = getState().search.saved[view];

  if (!saved.data || saved.loading || saved.data.last) {
    return;
  }

  dispatch(setSavedSearchesLoading({ loading: true, view }));

  try {
    const offset = saved.data?.content.length;
    const result = await axios(
      {
        method: 'GET',
        url: `${API_URL}/search-group?view=${view}&offset=${offset}&limit=${LIMIT}`,
      },
      dispatch,
    );

    dispatch(
      setMoreSavedSearches({
        data: result.data,
        view,
      }),
    );
  } catch (error: any) {
    dispatch(setSavedSearchesError({ error, view }));
  }
};

export const loadSavedSearch: LoadSavedSearch = (savedSearch, imageLocator) => async (dispatch) => {
  const { params, filterItems } = savedSearch.search;
  const { nlpFilters } = getNlPFilters(params, filterItems);

  const getText = () => {
    if (params?.polygonId) {
      return MY_DRAWN_AREA;
    }

    if (params?.rlat) {
      return MY_LOCATION;
    }

    if (savedSearch.searchTerms) {
      return savedSearch.searchTerms.map((term) => term.name).join(' ');
    }

    return '';
  };

  dispatch(clearNlpData());
  dispatch(
    setSearch({
      text: getText(),
      filters: {
        ...initialState.filters,
        ...nlpFilters,
        polyIds: params?.polygonId || [],
        lat: params?.rlat || null,
        lng: params?.rlng || null,
      },
    }),
  );

  dispatch(setView('list'));

  await dispatch(propertySearch(imageLocator));
};

export const saveSearch: SaveSearch = (name, emailFrequency) => async (dispatch, getState) => {
  try {
    const { search } = getState();

    const params = {
      ...DEFAULT_SEARCH_TERMS,
      ...search.resultParams,
      polygonId: search.filters.polyIds,
      lat: search.filters.lat,
      lng: search.filters.lng,
      radius: search.filters.radius,
    };

    const result = await axios(
      {
        method: 'POST',
        url: `${API_URL}/saved-search`,
        data: {
          name,
          emailFrequency,
          params,
        },
      },
      dispatch,
    );

    dispatch(setPropertySavedSearch(result.data));
    dispatch(setMapSavedSearch(result.data));

    // TODO possible - dispatch update to saved search list
    // current response doesn't match format from search-group

    return true;
  } catch {
    return false;
  }
};

export const updateSavedSearch: UpdateSavedSearch =
  (id, emailFrequency, selfOwned, name) => async (dispatch, getState) => {
    try {
      let result = null;

      if (selfOwned) {
        result = await axios(
          {
            method: 'PATCH',
            url: `${API_URL}/saved-search/${id}`,
            data: {
              emailFrequency,
            },
          },
          dispatch,
        );
      } else {
        result = await axios(
          {
            method: 'POST',
            url: `${API_URL}/saved-search?source=${id}`,
            data: {
              name,
              emailFrequency,
            },
          },
          dispatch,
        );
      }

      if (result.data) {
        dispatch(setPropertySavedSearch(result.data));
        dispatch(setMapSavedSearch(result.data));
      }

      // when !selfOwned the id changes for search item in list, so needs to be updated
      // otherwise it will fail to delete/update without reloading list
      // passing searchId property as id from result to update the search id
      dispatch(setSavedSearch({ id, emailFrequency, searchId: result?.data?.id || id }));

      return { success: true };
    } catch (err: any) {
      // if 404, saved search has been deleted, so cannot be updated, toast message should reflect this
      // remove the saved search from state
      const isDeleted = err?.response?.status === 404;
      if (isDeleted) {
        const { properties, map } = getState();

        dispatch(removeSavedSearch({ id, selfOwned }));

        if (properties.data?.savedSearch?.id === id) {
          dispatch(setPropertySavedSearch(undefined));
        }

        if (map.savedSearch?.id === id) {
          dispatch(setMapSavedSearch(undefined));
        }
      }
      return {
        success: false,
        toast: isDeleted ? 'Saved Search cannot be updated as the Saved Search cannot be found.' : undefined,
      };
    }
  };

export const deleteSavedSearch: DeleteSavedSearch = (id, selfOwned) => async (dispatch, getState) => {
  try {
    const { properties, map } = getState();

    await axios(
      {
        method: 'DELETE',
        url: `${API_URL}/saved-search/${id}`,
      },
      dispatch,
    );

    dispatch(removeSavedSearch({ id, selfOwned }));

    if (properties.data?.savedSearch?.id === id) {
      dispatch(setPropertySavedSearch(undefined));
    }

    if (map.savedSearch?.id === id) {
      dispatch(setMapSavedSearch(undefined));
    }

    return true;
  } catch {
    return false;
  }
};
